// i18next-extract-mark-ns-start products-fearce-durability

import { FEARCE_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductFearcePage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							FEARCE incorporates a fatigue module that includes a
							large array of linear and non-linear durability
							algorithms. Linear algorithms include the Goodman and
							Gerber methods. Multiaxial algorithms include the Dang
							Van, McDiarmid and Goodman methods. For non-linear
							analyses, the SWT, Brown-Miller, and Fatemi and Socie
							methods can be employed.
						</p>
						<p>
							In addition to the variety of fatigue algorithms, FEARCE
							also provides alternative approaches to calculating a
							stress tensor from principal stresses. These include the
							Von Mises approach (signed and unsigned), the maximum
							principal stress approach, the P1 principal stress
							approach and the ASME approach.
						</p>
						<p>
							FEARCE can perform reliability calculations defined by
							standard deviation on all material properties and loads.
							This enables the calculation of the number of failures
							within a given life span. FEARCE can also calculate
							fatigue safety factors for defined regions based on
							defined life, stress histories for non-linear analyses,
							and Haigh and Dang Van diagrams for linear analyses. All
							results can be displayed on the actual finite element
							(FE) model as numeric values or colour contours.
						</p>
						<h2>Key features</h2>
						<ul>
							<li>
								Large array of linear and non-linear fatigue
								algorithms
							</li>
							<li>
								Flexibility in equivalent uniaxial stress
								calculation
							</li>
							<li>Automatic generation of Haigh diagrams</li>
							<li>Results displayed directly in models</li>
							<li>
								Reliability calculations needed for predicting the
								number of failures
							</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{FEARCE_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductFearcePage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-fearce-durability", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/fearce-hero.jpg" }) {
		...imageBreaker
	}
}
`;
